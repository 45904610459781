<template>
  <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.5"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "XMarkIcon",
}
</script>

<style scoped></style>
